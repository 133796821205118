import axios from "axios";
import { AUTH, DHBB } from "../Constants";

export const getMaterials = (type: string | null) => {
  if (type) {
    return axios
      .get(`${DHBB}/material`, { params: { type } })
      .then((res) => res.data);
  } else {
    return axios.get(`${DHBB}/material`).then((res) => res.data);
  }
};

export const getMaterial = (id: number | null | undefined) =>
  axios.get(`${DHBB}/material/${id}`).then((res) => res.data);

export const getMaterialType = () =>
  axios.get(`${DHBB}/material/type`).then((res) => res.data);

export const getUom = () =>
  axios.get(`${DHBB}/material/uom`).then((res) => res.data);

export const addmaterial = (payload: any) =>
  axios.post(`${DHBB}/material`, payload).then((res) => res.data);

export const updatematerial = (payload: any) => {
  const { id } = payload;
  console.log({ id });
  return axios.post(`${DHBB}/material/${id}`, payload).then((res) => res.data);
};

export const deletematerial = (id: number) =>
  axios.delete(`${DHBB}/material/${id}`).then((res) => res.data);

import {
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { FaTimes } from "react-icons/fa";
import { createPortal } from "react-dom";

type Props = {
  children: React.ReactNode;
};

export type ModalHandle = {
  open: () => void;
  close: () => void;
};

const modalElement = document.getElementById("modal-root")!;

export const Modal = forwardRef((props: Props, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (e: KeyboardEvent) => e.keyCode === 27 && close(),
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div className="fixed overflow-hidden bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-10 inset-0">
        <div className="w-full h-full absolute top-0 left-0" onClick={close} />
        <span
          role="button"
          className="absolute right-4 top-4 cursor-pointer p-2 flex justify-center items-center text-center bg-white text-xl rounded-full z-30 border border-gray-100"
          aria-label="close"
          onClick={close}
        >
          <FaTimes />
        </span>
        <div className="z-20 relative mx-auto bg-white p-4 w-full md:w-1/2 lg:w-1/3">
          {props.children}
        </div>
      </div>
    ) : null,
    modalElement
  );
});

import { useRef, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DHBB, priceSegment } from "../Constants";
import { Modal, ModalHandle } from "../components";
import {
  getMaterial,
  getMaterials,
  getMaterialType,
  getUom,
  addmaterial,
  updatematerial,
  deletematerial,
} from "../services/dhbb";
import { getProfile } from "../services/auth";
import { MdExitToApp } from "react-icons/md";
import { logout } from "../stores/slices/authSlice";

export const Home = () => {
  const location = useLocation();
  const isLoggedIn =
    useSelector((state: any) => state.auth) ||
    localStorage.getItem("@accesstoken");

  const token = localStorage.getItem("@accesstoken");

  const modalAdd = useRef<ModalHandle>(null);

  const materialtype = useQuery(
    ["materialtype"],
    async () => await getMaterialType()
  );

  const uom = useQuery(["uom"], async () => await getUom());

  const [type, setType] = useState(null);

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <div className="">
      <div className="bg-lime-100">
        <div className="container mx-auto px-2 flex flex-row justify-between items-center py-2">
          <h2>DHBB</h2>
          {isLoggedIn && <LoginMenu />}
        </div>
      </div>
      <div className="container mx-auto px-2">
        <div className="flex flex-row justify-between items-center py-5">
          <h2 className="text-lg font-semibold">List Harga</h2>
          <button
            className="bg-emerald-500 px-4 py-1 text-white rounded-sm"
            type="button"
            onClick={() => modalAdd?.current?.open()}
          >
            Tambah
          </button>
        </div>
      </div>
      <Content type={type} />
      <Modal ref={modalAdd}>
        <FormMaterial onClose={() => modalAdd?.current?.close()} />
      </Modal>
    </div>
  );
};

const Content = ({ type }: any) => {
  const [selected, setSelected] = useState(undefined);
  const token = localStorage.getItem("@accesstoken");
  const modalEdit = useRef<ModalHandle>(null);
  const content = useQuery(
    ["materials", type],
    async () => await getMaterials(type)
  );

  const userinfo = useQuery(["userinfo"], async () => await getProfile(token), {
    enabled: !!token,
  });

  if (userinfo.isLoading || content.isLoading) {
    return (
      <div className="mt-5 py-20 container mx-auto text-center">Loading...</div>
    );
  }

  if (userinfo.isError || content.isError) {
    return (
      <div className="mt-5 py-20 container mx-auto text-center">
        Oops.. Error fetching data...
      </div>
    );
  }

  return (
    <div className="mt=5 container mx-auto px-2">
      <table className="table w-full">
        <thead className="bg-emerald-200">
          <tr>
            <th className="py-1 px-2">Material</th>
            <th className="py-1 px-2">UoM</th>
            <th className="py-1 px-2">Kategori</th>
            <th className="py-1 px-2">Harga (Rp)</th>
          </tr>
        </thead>
        {userinfo.data && (
          <tbody>
            {content.data.length > 0 ? (
              content.data.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className="py-1 px-2">
                      {userinfo.data.access.includes("DHBB_CRUD_ALL") ? (
                        <a
                          href="#"
                          onClick={() => {
                            setSelected(item.id);
                            modalEdit?.current?.open();
                          }}
                          className="text-emerald-700"
                        >
                          {item.name}
                        </a>
                      ) : (
                        item.name
                      )}
                    </td>
                    <td className="py-1 px-2">{item.uom}</td>
                    <td className="py-1 px-2">{item.material_type}</td>
                    <td className="py-1 px-2 text-right">
                      {item.base_price.toLocaleString("ID-id")}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <Modal ref={modalEdit}>
        <FormMaterial
          id={selected}
          onClose={() => modalEdit?.current?.close()}
        />
      </Modal>
    </div>
  );
};

const FormMaterial = ({ id, onClose }: any) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const materialtype = useQuery(
    ["materialtype"],
    async () => await getMaterialType()
  );

  const uom = useQuery(["uom"], async () => await getUom());

  const material = useQuery(
    ["material", id],
    async () => await getMaterial(id),
    { enabled: !!id }
  );

  const initialValue = {
    name: material.data?.name || "",
    code: material.data?.code || "",
    uom_id: material.data?.uom_id || uom.data[0].id,
    base_price: material.data?.base_price || 0,
    material_type_id:
      material.data?.material_type_id || materialtype.data[0].id,
    overhead_atam: material.data?.overhead_atam || 0,
    overhead_langsa: material.data?.overhead_langsa || 0,
    overhead_atim: material.data?.overhead_atim || 0,
    overhead_acut: material.data?.overhead_acut || 0,
    overhead_a000: material.data?.overhead_a000 || 0,
    overhead_ak01: material.data?.overhead_ak01 || 0,
    overhead_ak02: material.data?.overhead_ak02 || 0,
    overhead_ak03: material.data?.overhead_ak03 || 0,
    overhead_ak04: material.data?.overhead_ak04 || 0,
    overhead_ak05: material.data?.overhead_ak05 || 0,
    overhead_ak06: material.data?.overhead_ak06 || 0,
    overhead_ap01: material.data?.overhead_ap01 || 0,
    overhead_ap02: material.data?.overhead_ap02 || 0,
    overhead_ap06: material.data?.overhead_ap06 || 0,
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(addmaterial, {
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ["materials"] });
      onClose();
    },
  });

  const update = useMutation(updatematerial, {
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ["materials"] });
      queryClient.refetchQueries({ queryKey: ["material", id] });
      onClose();
    },
  });

  const handleSubmit = (payload: any) => {
    id ? update.mutate({ ...payload, id }) : mutation.mutate(payload);
    onClose();
  };

  if (id && material.isLoading) {
    return <div>Loading...</div>;
  }

  if (id && material.isError) {
    return <div>Error...</div>;
  }

  return (
    <>
      <Formik
        initialValues={initialValue}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setTimeout(() => setSubmitting(false), 100);
        }}
        onReset={onClose}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          dirty,
          isSubmitting,
        }) => (
          <Form>
            <div className="h-[500px] overflow-y-auto px-2">
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="name">Nama Material</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="code">Kode Material</label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.code}
                />
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="uom_id">UoM</label>
                <select
                  name="uom_id"
                  id="uom_id"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.uom_id}
                >
                  {uom.data.map((item: any, index: number) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="material_type_id">Kategori</label>
                <select
                  name="material_type_id"
                  id="material_type_id"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.material_type_id}
                >
                  {materialtype.data.map((item: any, index: number) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="base_price">Harga Dasar (Rp)</label>
                <input
                  type="number"
                  name="base_price"
                  id="base_price"
                  onFocus={() =>
                    values.base_price === 0 && setFieldValue("base_price", "")
                  }
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2 text-right"
                  onChange={handleChange}
                  value={values.base_price}
                />
              </div>
              {priceSegment.length > 0 &&
                priceSegment.map((item: any, index: number) => (
                  <Segment key={index} data={item} />
                ))}
            </div>
            <div className="grid grid-cols-2 gap-2 mt-5">
              <button
                type="submit"
                className="bg-emerald-500 px-4 py-1 text-white rounded-sm"
              >
                SIMPAN
              </button>
              <button
                type="reset"
                className="bg-slate-200 px-4 py-1 text-gray-800 rounded-sm"
              >
                BATAL
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const FormContent = ({
  initialValue,
  onClose,
  materialtype,
  uom,
  onSubmit,
}: any) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addmaterial, {
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ["materials"] });
      onClose();
    },
  });

  const handleSubmit = (payload: any) => {
    console.log(payload);
    mutation.mutate(payload);
    onClose();
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setTimeout(() => setSubmitting(false), 100);
        }}
        onReset={onClose}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          dirty,
          isSubmitting,
        }) => (
          <Form>
            <div className="h-[500px] overflow-y-auto px-2">
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="name">Nama Material</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="code">Kode Material</label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.code}
                />
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="uom_id">UoM</label>
                <select
                  name="uom_id"
                  id="uom_id"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.uom_id}
                >
                  {uom.map((item: any, index: number) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="material_type_id">Kategori</label>
                <select
                  name="material_type_id"
                  id="material_type_id"
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2"
                  onChange={handleChange}
                  value={values.material_type_id}
                >
                  {materialtype.map((item: any, index: number) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-3 gap-2 mb-1 items-center">
                <label htmlFor="base_price">Harga Dasar (Rp)</label>
                <input
                  type="number"
                  name="base_price"
                  id="base_price"
                  onFocus={() =>
                    values.base_price === 0 && setFieldValue("base_price", "")
                  }
                  className="text-sm border outline-none w-full py-1 px-2 rounded-sm col-span-2 text-right"
                  onChange={handleChange}
                  value={values.base_price}
                />
              </div>
              {priceSegment.length > 0 &&
                priceSegment.map((item: any, index: number) => (
                  <Segment key={index} data={item} />
                ))}
            </div>
            <div className="grid grid-cols-2 gap-2 mt-5">
              <button
                type="submit"
                className="bg-emerald-500 px-4 py-1 text-white rounded-sm"
              >
                SIMPAN
              </button>
              <button
                type="reset"
                className="bg-slate-200 px-4 py-1 text-gray-800 rounded-sm"
              >
                BATAL
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Segment = ({ data }: any) => {
  return (
    <section className="flex flex-col mt-2">
      <h2 className="text-lg font-semibold border-b pb-1 mb-1">{data.zone}</h2>
      <SegmentItem fieldName={data.shortname} />
      {data.unit.length > 0 &&
        data.unit.map((obj: any, index: number) => (
          <SegmentItem key={index} fieldName={obj.name} indent={3} />
        ))}
    </section>
  );
};

const SegmentItem = ({ fieldName, indent }: any) => (
  <div className="grid grid-cols-3 gap-2 items-center mb-1">
    <Field name={fieldName} id={fieldName} type="number">
      {({ field: { value }, form: { setFieldValue, values } }: any) => (
        <>
          <label
            htmlFor={`overhead_${fieldName}`}
            className={`text-sm indent-${indent}`}
          >
            {fieldName}
          </label>
          <input
            type="number"
            name={`overhead_${fieldName}`}
            id={`overhead_${fieldName}`}
            className="text-sm border outline-none w-full py-1 px-3 rounded-sm text-right"
            onFocus={() =>
              values[`overhead_${fieldName.toLowerCase()}`] === 0 &&
              setFieldValue(`overhead_${fieldName.toLowerCase()}`, "")
            }
            onBlur={() =>
              values[`overhead_${fieldName.toLowerCase()}`] === "" &&
              setFieldValue(`overhead_${fieldName.toLowerCase()}`, 0)
            }
            onChange={(e) => {
              const name = fieldName.toLowerCase();
              const value = e.currentTarget.value.replace(".", "");
              switch (name) {
                case "atam":
                  setFieldValue(`overhead_${name}`, value);
                  setFieldValue("overhead_ak01", value);
                  setFieldValue("overhead_ap01", value);
                  setFieldValue("overhead_ap02", value);
                  break;
                case "langsa":
                  setFieldValue(`overhead_${name}`, value);
                  setFieldValue("overhead_a000", value);
                  setFieldValue("overhead_ak02", value);
                  setFieldValue("overhead_ak03", value);
                  break;
                case "atim":
                  setFieldValue(`overhead_${name}`, value);
                  setFieldValue("overhead_ak04", value);
                  setFieldValue("overhead_ak05", value);
                  break;
                case "acut":
                  setFieldValue(`overhead_${name}`, value);
                  setFieldValue("overhead_ak06", value);
                  setFieldValue("overhead_ap06", value);
                  break;
                default:
                  setFieldValue(`overhead_${name}`, value);
              }
            }}
            value={values[`overhead_${fieldName.toLowerCase()}`].toLocaleString(
              "ID-id"
            )}
          />
          <input
            type="text"
            disabled
            className="outline-none w-full py-1 px-3 text-right"
            value={(
              Number(values[`overhead_${fieldName.toLowerCase()}`]) +
              Number(values.base_price)
            ).toLocaleString("ID-id")}
          />
        </>
      )}
    </Field>
  </div>
);

const LoginMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout(null));
    localStorage.removeItem("@accesstoken");
    navigate("/");
  };

  return (
    <div
      onClick={handleLogout}
      className="absolute right-0 top-7 flex justify-center items-center px-2 cursor-pointer sm:relative sm:inset-0 sm:h-full"
      title="Logout"
    >
      <MdExitToApp className="text-2xl text-red-800" />
    </div>
  );
};

import axios from "axios";
import { AUTH, APP_TOKEN } from "../Constants";

export const postLogin = (payload: any) =>
  axios
    .post(`${AUTH}/login`, { ...payload, app_token: APP_TOKEN })
    .then((res) => res.data);

export const logout = () =>
  axios.post(`${AUTH}/logout`).then((res) => res.data);

export const refreshToken = () =>
  axios.post(`${AUTH}/refreshtoken`).then((res) => res.data);

export const getProfile = (token: string | null) =>
  axios
    .get(`${AUTH}/me`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

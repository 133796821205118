import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Oval } from "react-loader-spinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import { postLogin } from "../services/auth";
import { login } from "../stores/slices/authSlice";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("@accesstoken");
  const mutation = useMutation(postLogin, {
    onSuccess: (data) => {
      if (data.access_token) {
        dispatch(login(data.access_token));
        localStorage.setItem("@accesstoken", data.access_token);
        navigate("/");
      }
    },
  });

  if (token) {
    return <Navigate to="/" replace />;
  }

  const initialValue = {
    username: "",
    password: "",
  };

  const handleLogin = (payload: any) => {
    mutation.mutate(payload);
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="sm:w-1/2 md:w-2/5 lg:w-1/4 py-10 mx-auto">
        <h2 className="text-center font-semibold text-lg mb-5">LOGIN</h2>
        <Formik
          initialValues={initialValue}
          onSubmit={(values, { setSubmitting }) => {
            handleLogin(values);
            setTimeout(() => setSubmitting(false), 100);
          }}
        >
          {({ values, handleChange, handleSubmit, dirty, isSubmitting }) => (
            <Form>
              <input
                type="text"
                name="username"
                className="border outline-none w-full py-2 px-3 mb-2 rounded-sm"
                onChange={handleChange}
                value={values.username}
              />
              <input
                type="password"
                name="password"
                className="border outline-none w-full py-2 px-3 mb-2 rounded-sm"
                onChange={handleChange}
                value={values.password}
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`${
                  isSubmitting
                    ? "bg-lime-100 text-gray-50  "
                    : "bg-lime-600 text-white "
                }mt-4 w-full py-2 rounded-md tracking-wide`}
              >
                {isSubmitting ? (
                  <Oval
                    height="18"
                    width="80"
                    color="#4fa94d"
                    wrapperClass="w-full justify-center items-center h-full"
                    visible={true}
                  />
                ) : (
                  `Sign In`
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  data: null as string | null,
  loading: "idle" as "idle" | "pending" | "success" | "failed",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    logout(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;

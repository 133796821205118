export const DHBB = "https://dev.hris.ptpn1.co.id/v1";
export const AUTH = "https://dev.auth.ptpn1.co.id/v1";

export const APP_TOKEN = "l48S5uOa6u9X5OUEnU3xzd3KAzF3zvNu";

export const priceSegment = [
  {
    zone: "Wilayah Aceh Tamiang",
    shortname: "ATam",
    unit: [{ name: "AK01" }, { name: "AP01" }, { name: "AP02" }],
  },
  {
    zone: "Wilayah Kota Langsa",
    shortname: "Langsa",
    unit: [{ name: "A000" }, { name: "AK02" }, { name: "AK03" }],
  },
  {
    zone: "Wilayah Aceh Timur",
    shortname: "ATim",
    unit: [{ name: "AK04" }, { name: "AK05" }],
  },
  {
    zone: "Wilayah Aceh Utara",
    shortname: "AcUt",
    unit: [{ name: "AK06" }, { name: "AP06" }],
  },
];

const groupName = [
  {
    name: "Kebun Pulau Tiga",
    code: "AK01",
  },
  {
    name: "Kebun Lama",
    code: "AK02",
  },
  {
    name: "Kebun Baru",
    code: "AK03",
  },
  {
    name: "Kebun Tualang Sawit",
    code: "AK04",
  },
  {
    name: "Kebun Julok Rayeuk Utara",
    code: "AK05",
  },
  {
    name: "Kebun Cot Girek",
    code: "AK06",
  },
  {
    name: "PKS Pulau Tiga",
    code: "AP01",
  },
  {
    name: "PKS Tanjung Seumantoh",
    code: "AP02",
  },
  {
    name: "PKS Cot Girek",
    code: "AP06",
  },
];
